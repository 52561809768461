import Swal from 'sweetalert2';
export default {
  init() {
    // JavaScript to be fired on single posts
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
        
    console.log('[Single post] Init');

    /*
    $('#register-form-submit').on( 'click', function(e) {
      e.preventDefault();
      Swal.fire({
        title: 'Registrera',
        html: 'Godkänner du att vi sparar ditt för- och efternamn samt din e-postadress enligt våra <a href="' + terms_url + '" target="_blank">allmänna villkor</a>?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Nej',
        confirmButtonText: 'Ja',
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          $('#registerform').submit();
        }
      });
    });
    */

    $('#create-subscription-stripe').on( 'click', function(e) {

      console.log('[Single post] Create subscription via Stripe');

      //$(this).prop('disabled', true);

      var cancel_url = $(this).data('cancel-url');

      e.preventDefault();

      $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          cancelurl: cancel_url,
          action: 'create_subscription_stripe',
        },
        success: function(url) {
          location.href = url;
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.warn('Response', errorThrown);
          //$(this).prop('disabled', false);
        },
      });

    });

    var check_premium = getUrlParameter('premium');
    var check_register = getUrlParameter('action');
    console.log(check_premium);
    if(check_register == 'register' && check_premium === false) {

      console.log('[Single post] Register complete, redirecting to Stripe');

      $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          cancelurl: window.location.origin + window.location.pathname,
          action: 'create_subscription_stripe',
        },
        success: function(url) {
          location.href = url;
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.warn('Response', errorThrown);
          //$(this).prop('disabled', false);
        },
      });

    }

    if(check_premium == 1) {

      console.log('[Single post] Verifying premium');

      Swal.fire({
        title: 'Verifierar prenumeration',
        html: 'Vänligen vänta, sidan uppdateras automatiskt',
        didOpen: () => {
          Swal.showLoading()
        },
      })

      var check_premium_attempts = 0;
      var check_premium_interval = setInterval(function() {

        console.log('check_premium_attempts', check_premium_attempts);

        if(check_premium_attempts < 15) {
          $.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
              action: 'check_subscription',
            },
            success: function(response) {
              response = JSON.parse(response);
              console.log('[Single post] Verifying premium', response.status);
              if(response.status == 'active') {
                location.href = window.location.href.split('?')[0];
              }
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              console.warn('Response', errorThrown);
            },
          });
        } else {
          console.log('[Single post] Verifying premium clear interval');
          clearInterval(check_premium_interval);

          Swal.fire({
            icon: 'error',
            title: 'Kunde ej verifiera prenumeration',
            text: 'Vänligen kontakta oss om du har en aktiv prenumeration',
            buttonsStyling: false,
          })
          
        }

        check_premium_attempts++;

      }, 2000);

    }
    
  },
  finalize() {
    // JavaScript to be fired on single posts, after the init JS
  },
};
