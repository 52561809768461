export default {
  init() {
    // JavaScript to be fired on the home page
    function autoFitText(element, container, minFontSize, maxFontSize) {

      $(element).each(function() {

        var containerWidth;
        if(container === 'parent') {
          containerWidth = $(this).parent().width();
        } else {
          containerWidth = $(container).width();
        }
        var textWidth = $(this).outerWidth();
        var fontSize = parseInt($(this).css('font-size'), 10);
                    
        if(containerWidth > textWidth) {
          while($(this).outerWidth() < containerWidth && maxFontSize > fontSize) {
            fontSize++;
            $(this).css('font-size', fontSize); 
          }
          // Just a last check to see if the text has grown too much. If so, adjust it down one step
          if ($(this).outerWidth() > containerWidth && minFontSize < fontSize) {
            fontSize--;
            $(this).css('font-size', fontSize); 
          }
        } else {
          while($(this).outerWidth() > containerWidth && minFontSize < fontSize) {
            fontSize--;
            $(this).css('font-size', fontSize); 
          }
        }

      });

    }

    autoFitText('.front-page-news-slider-caption-text', 'parent', 8, 27);

    $(window).on('resize', function() {
      autoFitText('.front-page-news-slider-caption-text', 'parent', 8, 27);
    });

    $('.front-page-news-slider').slick({
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
