// import external dependencies
import 'jquery';
import 'custom-event-polyfill';
import 'slick-carousel';
import 'masonry-layout/dist/masonry.pkgd.js';
import 'featherlight';

// Import everything from autoload
import './autoload/_bootstrap.js'

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt, faMapMarkerAlt, faPhone, faAngleDown, faAngleRight, faLongArrowAltDown, faCircleNotch, faRssSquare, faSearch, faLink, faPrint, faUserLock, faBars, faUmbrella, faWind, faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import { faUser, faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare, faStripe } from '@fortawesome/free-brands-svg-icons';
library.add( faExternalLinkAlt, faMapMarkerAlt, faPhone, faAngleDown, faAngleRight, faLongArrowAltDown, faCircleNotch, faRssSquare, faSearch, faLink, faPrint, faUserLock, faBars, faUmbrella, faWind, faTemperatureHigh, faUser, faClock, faFacebook, faTwitter, faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare, faStripe);
dom.watch();

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import singlePost from './routes/single-post';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // Single posts
  singlePost,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
