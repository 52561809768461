import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
export default {
  init() {
    // JavaScript to be fired on all pages
    $.fn.randomize = function (selector) {
      var $elems = selector ? $(this).find(selector) : $(this).children(),
        $parents = $elems.parent();

      $parents.each(function () {
        $(this).children(selector).sort(function (childA, childB) {
          // * Prevent last slide from being reordered
          if($(childB).index() !== $(this).children(selector).length - 1) {
            return Math.round(Math.random()) - 0.5;
          }
        }.bind(this)).detach().appendTo(this);
      });

      return this;
    };

    // Bootstrap
    $('[data-toggle="tooltip"]').tooltip();

    // Lightbox
    $('.wp-post-image, .entry-content figure img').featherlight({
      targetAttr: 'src',
    });

    // Current date
    /*
    var d = new Date();
    var weekdays = new Array('Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag');
    var weekdaysShort = new Array('Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör');
    var months = new Array('januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december');
    var monthsShort = new Array('jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec');
    var day = weekdays[d.getDay()];
    var dayShort = weekdaysShort[d.getDay()];
    var date = d.getDate();
    var month = months[d.getMonth()];
    var monthShort = monthsShort[d.getMonth()];
    var currentDate = day + ' ' + date + ' ' + month + ', ' + d.getFullYear();
    var currentDateMobile = dayShort + ' ' + date + ' ' + monthShort;
    var currentMonthDate = (d.getMonth()+1) + '' + d.getDate();
    */
    
    // Current weather and nameday
    $.getJSON( weather_data_url, { type: 'full' }, function(data) {

      if(data) {
        var currentUnixtime = Math.round(new Date().getTime()/1000);
        var currentWeather;
        $.each(data, function(key, value) {
          if(key < currentUnixtime) {
            if(value.range === 1) {
              currentWeather = value.short_human;
              return false;
            }
          }
        });
        if(currentWeather) {
          $('#current-weather').html(currentWeather);
        }
      }

      /*
      $('#current-date').html(currentDate);
      $('#current-date-mobile').html(currentDateMobile);

      if(data['namedays']) {
        var currentNameday;
        $.each(data['namedays'], function(key, value) {
          if(key == currentMonthDate) {
            currentNameday = value;
          }
        });
        if(currentNameday.names) {
          $('#current-nameday').html(currentNameday.names);
        }
        if(currentNameday.flagday) {
          $('#current-date-flagday').attr('data-original-title', currentNameday.flagday).show();
        }
      }

      if(data['weather']) {
        var currentUnixtime = Math.round(new Date().getTime()/1000);
        var currentWeather;
        $.each(data['weather'], function(key, value) {
          if(key < currentUnixtime) {
            if(value.range === 1) {
              currentWeather = value.short_human;
              return false;
            }
          }
        });
        if(currentWeather) {
          $('#current-weather').html(currentWeather);
        }
      }
      */

    });

    // Cookies
    $('#cookie-deny').on( 'click', function(e) {
      e.preventDefault();
      var cookieConsent = Cookies.get('ld_cookie_consent');
      if(cookieConsent != null) {
        Cookies.remove('ld_cookie_consent');
      }
      Cookies.set('ld_cookie_consent', 0, { expires: 30 });
      $('.site-cookies-current-setting').hide();
      $('#site-cookies').modal('hide');
    });

    $('#cookie-accept').on( 'click', function(e) {
      e.preventDefault();
      Cookies.set('ld_cookie_consent', 1, { expires: 365 });
      $('.site-cookies-current-setting').hide();
      $('#site-cookies').modal('hide');
    });

    $('#cookie-settings').on( 'click', function(e) {
      e.preventDefault();
      var cookieConsent = Cookies.get('ld_cookie_consent');
      if(cookieConsent != null) {
        $('#site-cookies-current-setting-accept').show();
      } else {
        $('#site-cookies-current-setting-deny').show();
      }
      $('#site-cookies').modal('show');
    });

    // Companies
    $('#companies-slider').randomize().slick({
      lazyLoad: 'ondemand',
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    }).css('visibility', 'visible');

    // Ads supertoppbanner
    $('#ads-supertoppbanner-slider').randomize().slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    });

    // Ads toppbanner
    $('#ads-toppbanner-slider').randomize().slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    });
    
    // Ads mellanbanner
    $('#ads-mellanbanner-slider').randomize().slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    });
    
    // Ads nyhetsbanner
    $('#ads-nyhetsbanner-slider').randomize().slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    });
    
    // Ads bottenbanner
    $('#ads-bottenbanner-slider').randomize().slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      fade: true,
      cssEase: 'linear',
    });

    // Ads annonstavla
    $('#ads-annonstavla').randomize();

    // Ads annonstavla
    $('#ads-block').randomize();
    
    // Ads click
    $('.ad-link').on( 'click touchstart', function() {

      var ad_click = $(this).data('click');
      var ad_id = $(this).data('id');

      if(ad_click === 0) {

        $(this).data('click', 1);

        $.ajax({
          url: ajax_url,
          type: 'POST',
          data: {
            action: 'ic_ad_click',
            ad_id: ad_id,
            global_nonce: global_nonce,
          },
        });

      }

    });

    // Weather forecast
    $('.summary').on( 'click touchstart', function(e) {
      e.preventDefault();
      var date = $(this).data('date');
      $('.forecast').hide();
      $('.forecast' + date).show();
      $('.page-header').get(0).scrollIntoView();
    });
    
    // Single posts copy permalink
    $('#copy-permalink').on( 'click', function() {

      var $temp = $('<input>');
      var $url = $(location).attr('href');
      $('body').append($temp);
      $temp.val($url).select();
      document.execCommand('copy');
      $temp.remove();

      Swal.fire({
        icon: 'success',
        title: 'Länk kopierad',
        buttonsStyling: false,
      })

      console.log('[Single post] Copy permalink', $url);

    });

    // Single posts print
    $('#print').on( 'click', function() {
      window.print();
      console.log('[Single post] Print');
    });
    
    // Lunchguide 
    $('#lunchShowToday').on( 'click', function(e) {
      
      e.preventDefault();
      
      if(!$(this).hasClass('btn-primary')) {
        $('#lunchShowToday').toggleClass('btn-primary');
        $('#lunchShowWeek').toggleClass('btn-primary');
      }
      
      var date = new Date();
      var today = date.getFullYear() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2);

      $('.lunchMenyDaily').each(function() {
        var lunch_date = $(this).data('date');
        if(lunch_date != today) {
           $(this).hide();
        }
      });
      
    });
    
    $('#lunchShowWeek').on( 'click', function(e) {

      e.preventDefault();

      if(!$(this).hasClass('btn-primary')) {
        $('#lunchShowToday').toggleClass('btn-primary');
        $('#lunchShowWeek').toggleClass('btn-primary');
      }

      $('.lunchMenyDaily').show();

    });
    
    $('#goToRestaurant').on('change', function(e) {
      e.preventDefault();
      if(this.value != 'start') {
        $('html, body').animate({
          scrollTop: $('#' + this.value).offset().top,
        }, 500);
      }
    });

    // Cancel subscription
    $('#cancel-subscription').on('click', function(e) {

      console.log('[Single post] Cancel subscription');

      $(this).prop('disabled', true);
      
      e.preventDefault();

      $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'cancel_subscription',
        },
        success: function() {
          console.log('[Single post] Subscription canceled');
          setTimeout(function(){
            location.reload();
          }, 5000);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.warn('Response', errorThrown);
          $(this).prop('disabled', false);
        },
      });

    });
    
    // Cancel subscription
    $('#reactivate-subscription').on('click', function(e) {

      console.log('[Single post] Reactivate subscription');

      $(this).prop('disabled', true);
      
      e.preventDefault();

      $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'reactivate_subscription',
        },
        success: function() {
          console.log('[Single post] Subscription reactivated');
          setTimeout(function(){
            location.reload();
          }, 5000);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.warn('Response', errorThrown);
          $(this).prop('disabled', false);
        },
      });

    });
    
    // Manage account
    $('#manage-subscriptions').on('click', function(e) {

      console.log('[Single post] Manage subscriptions');

      $(this).prop('disabled', true);
      
      e.preventDefault();

      $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'manage_subscriptions',
        },
        success: function(url) {
          location.href = url;
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.warn('Response', errorThrown);
          $(this).prop('disabled', false);
        },
      });

    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    var cookieConsent = Cookies.get('ld_cookie_consent');
    if(cookieConsent != null) {
      console.log('[Cookies] Cookies accepted');
    } else {
      console.log('[Cookies] Cookies not accepted');
      //$('#site-cookies').modal('show');
    }
  },
};
